<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        {{ selectedData.title }}
        <v-spacer />

        <v-btn
          v-if="selectedData.status != 1 && !isUser"
          class="mx-1"
          dark
          color="error"
          elevation="0"
          @click="closeTicket"
        >
          <v-icon small dark>mdi-close</v-icon>
          بستن تیکت
        </v-btn>
        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <small>عنوان تیکت</small>
        <h4>{{ selectedData.desc || "" }}</h4>
        <v-divider class="my-2" />
        <div v-if="answers.length" class="answers-container">
          <div class="answers-parent px-2">
            <v-row v-for="(ans, i) in answers" :key="'ans' + i" no-gutters>
              <v-spacer v-if="ans.user_id != $store.state.userData.id" />
              <div
                class="rounded pa-2 mt-2 answer"
                :class="{
                  blue: ans.user_id == $store.state.userData.id,
                  'grey lighten-2': ans.user_id != $store.state.userData.id,
                }"
              >
                {{ ans.desc || "" }}
              </div>
              <v-spacer v-if="ans.user_id == $store.state.userData.id" />
            </v-row>
          </div>
        </div>
        <h4 class="mt-4" v-else>هنوز پاسخی دریافت نشده است.</h4>
        <div class="my-2">
          <div class="mt-4 d-flex">
            <v-textarea
              background-color="grey lighten-2"
              hide-details="auto"
              solo
              flat
              auto-grow
              label="متن جدید"
              dense
              rows="1"
              class="rounded-lg textarea-custom"
              v-model="desc"
            />
            <v-btn
              class="mx-1"
              dark
              color="primary"
              :loading="loading"
              @click="sendTicket"
            >
              ثبت
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      answers: [],
      desc: "",
    };
  },
  methods: {
    showModal() {
      this.dialog = true;
    },
    GetAnswers() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/tickets/index", {
        params: {
          noPaginate: true,
          with: ["user", "parent"],
          conditions: {
            parent_id: this.selectedData.id,
          },
        },
      })
        .then((response) => {
          this.loading = false;
          this.answers = response.data;
          this.$nextTick(() => {
            const elements = document.getElementsByClassName(
              "answers-container"
            );
            if (elements.length)
              elements[0].scrollTop = elements[0].scrollHeight;
          });
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    async setUnreadTicket() {
      return MyAxios.post("/tickets/" + this.selectedData.id + "/update", {
        status: this.isUser ? 0 : 1,
      })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {});
    },
    async closeTicket() {
      this.loading = true;
      await this.setUnreadTicket();
      this.dialog = false;
      this.loading = false;
      this.$root.$emit("toast", {
        text: "تیکت با موفقیت بسته شد.",
        type: "success",
      });
    },
    sendTicket() {
      if (this.desc <= 0) {
        this.$root.$emit("toast", {
          text: "پاسخ را به درستی وارد نمایید",
        });
        return;
      }

      if (!this.loading) this.loading = true;
      let formData = new FormData();

      formData.append("user_id", this.$store.state.userData.id);
      formData.append("parent_id", this.selectedData.id);
      formData.append("title", this.selectedData.title);
      formData.append("desc", this.desc);

      MyAxios.post("/tickets/create", formData)
        .then((response) => {
          this.desc = "";
          this.loading = false;
          this.$root.$emit("toast", {
            text: "تیکت شما با موفقیت ثبت شد.",
            type: "success",
          });
          this.setUnreadTicket();
          this.GetAnswers();
          console.log(response);
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("toast", {
            text: getErrorText(error),
          });
          console.log(error, error.response);
        });
    },
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
    isUser: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    selectedData() {
      this.answers = [];
      this.GetAnswers();
    },
    dialog: {
      immediate: true,
      handler(val) {
        if (!val) this.$emit("GetList");
      },
    },
  },
};
</script>

<style scoped>
.answer {
  max-width: 70%;
}
.answers-parent {
  display: -webkit-flex;
  flex-direction: column-reverse;
  position: relative;
}
.answers-container {
  overflow-y: auto;
  max-height: 60vh;
}
</style>
